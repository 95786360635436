<!--  -->
<template>
<pageBg :heightBg="heightBg">
    <div class="content clearfix">
        <div class="activeTop">

        </div>
        <div class="videoPlay ">
            <div class="videoDom" v-if="wallpaperType == 1">
                <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" @play="onPlayerPlay($event)" @ready="playerReadied" :options="playerOptions">
                </video-player>
            </div>
            <div class="ifreamDom" v-if="wallpaperType != 1">
                <iframe frameborder="0" :src="ifreamUrl" width="100%" height="100%" id="html" ref="html" allowfullscreen="true"></iframe>
            </div>
            <downLoadPc class="swbz"></downLoadPc>
            <div class="title">
                <p>{{wallpaperInfo.paper_name}}</p>
                <div class="right">
                    <div class="dz">
                        {{wallpaperInfo.collection_count}}
                    </div>
                    <div class="upload">
                        {{wallpaperInfo.down_count}}
                    </div>
                </div>

            </div>
        </div>
        <div class="videoList">
            <ul>
                <li v-for="(item,index) in wallpaperList" :key="index" :class="[activeIndex == index?'active':'']" @click="selectWallpaper(index)">
                    <img :src="item.img_url">
                    <p>{{item.paper_name}}</p>
                </li>
            </ul>
        </div>
    </div>

</pageBg>
</template>

<script>
import { alliance } from '@/utils'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import pageBg from '../../components/pageBg'
import { hotspot_wallpaper, details } from '../../api/wallpaper'
export default {
    name: '',
    components: {
        pageBg,
        videoPlayer
    },
    data() {
        return {
            heightBg: '1745px',
            videoUrl: '',
            ifreamUrl: '',
            activeIndex: 0,
            wallpaperList: [], //壁纸列表
            wallpaperId: null, //详情壁纸id
            wallpaperInfo: {},
            wallpaperType: 1, //壁纸类型
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: true, // 如果为true,浏览器准备好时开始回放。
                muted: true, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: '', // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: true, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        };
    },
    computed: {},
    created() {
        this.getWallpaperList()
    },
    mounted() {
      
    },
    methods: {
        playerReadied() {

        },
        onPlayerPlay(event) {
            this.$refs.videoPlayer.player.play()
        },
        getWallpaperList() {
            hotspot_wallpaper().then(res => {
                if (res.success) {
                    let num = 9;
                    if (res.data.length >= 9) {
                        num = 9
                    } else {
                        num = res.data.length
                    }
                    for (let i = 0; i < num; i++) {
                        this.wallpaperList.push(res.data[i])
                    }
                    this.wallpaperId = this.wallpaperList[0].paper_id
                    this.getDetails()
                }
            })
        },
        getDetails() {
            let obj = {
                paper_id: this.wallpaperId
            }
            details(obj).then(res => {
                if (res.success) {
                    this.wallpaperInfo = res.data
                    this.wallpaperType = this.wallpaperInfo.paper_type_id
                    if (this.wallpaperType == 1) {
                        let video = {
                            type: "video/mp4",
                            src: (res.data.preview_url + '?end=10') || (res.data.video_url + '?end=10')
                        }
                        this.playerOptions.sources = []
                        this.playerOptions.sources.push(video)
                        this.playerOptions.poster = res.data.img_url
                        this.onPlayerPlay()
                    } else {
                        this.ifreamUrl = res.data.preview_url
                    }

                }
            })
        },
        selectWallpaper(index) {
            this.activeIndex = index
            this.wallpaperId = this.wallpaperList[index].paper_id
            this.getDetails()
            alliance(34)
        }
    },
}
</script>

<style lang="scss" scoped>
.video-player {
  /deep/ .vjs-big-play-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ .vjs-play-progress:before,
  /deep/ .vjs-volume-level:before {
    top: -0.43em;
  }
}

.content {
  width: 1200px;
  margin: 0 auto;
}

.activeTop {
  position: absolute;
  width: 1920px;
  height: 541px;
  left: 50%;
  margin-left: -960px;
  background: url('../../assets/img/activation/activeTop.png') no-repeat;
  background-size: 100% 100%;
  top: 0;
  margin-top: -80px;
}

.videoPlay {
  width: 1064px;
  height: 652px;
  margin: 0 auto;
  margin-top: 154px;
  background: url('../../assets/img/wallpaper/video.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 9;

  &::before {
    position: absolute;
    content: '';
    width: 161px;
    height: 404px;
    background: url('../../assets/img/wallpaper/left.png') no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: -161px;
    z-index: 9;
  }

  &::after {
    position: absolute;
    content: '';
    width: 161px;
    height: 404px;
    background: url('../../assets/img/wallpaper/left.png') no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    right: -161px;
    transform: rotateY(180deg);
    z-index: 9;
  }

  .videoDom,
  .ifreamDom {
    width: 948px;
    height: 532px;
    position: absolute;
    left: 50%;
    margin-left: -475px;
    top: 17px;
  }

  .swbz {
    width: 264px;
    height: 50px;
    position: absolute;
    background: url('../../assets/img/wallpaper/swbz.png') no-repeat;
    background-size: 100% 100%;
    left: 50%;
    margin-left: -132px;
    bottom: 49px;
    cursor: pointer;

    &:hover {
      background: url('../../assets/img/wallpaper/swbz_a.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .title {
    position: absolute;
    width: 100%;
    height: 48px;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    line-height: 48px;
    padding: 0 16px;
    box-sizing: border-box;

    p {
      width: 600px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .right {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: flex;

      .dz {
        margin-right: 70px;
        position: relative;

        &::before {
          content: '';
          width: 24px;
          height: 24px;
          position: absolute;
          background: url('../../assets/img/wallpaper/z.png') no-repeat;
          background-size: 100% 100%;
          left: -38px;
          top: 13px;
        }
      }

      .upload {
        position: relative;

        &::before {
          content: '';
          width: 24px;
          height: 24px;
          position: absolute;
          background: url('../../assets/img/wallpaper/upload.png') no-repeat;
          background-size: 100% 100%;
          left: -38px;
          top: 10px;
        }
      }
    }
  }
}

.videoList {
  width: 1200px;
  margin-top: 50px;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 26px;
  background: rgba(255, 255, 255, 0.04);

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 384px;
      height: 266px;
      margin-right: 24px;
      margin-bottom: 24px;
      position: relative;
      cursor: pointer;

      &.active,
      &:hover {
        &::before {
          width: 406px;
          height: 290px;
          position: absolute;
          z-index: 9;
          left: -11px;
          top: -13px;
          content: '';
          background: url('../../assets/img/wallpaper/select.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: 216px;
      }

      p {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        line-height: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        box-sizing: border-box;
        background: linear-gradient(
          90deg,
          rgba(#8f63ff, 0.32),
          rgba(#2a4dff, 0.32)
        );
      }
      &.active {
        p {
          background: linear-gradient(90deg, #8f63ff, #2a4dff);
        }
      }
    }
  }
}
</style>
